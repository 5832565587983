<template>
  <div>
    <div class="row">
      <!-- <div class="alert alert-success mb-5 p-5" role="alert">
          <h4 class="alert-heading">Informações importantes!</h4>
          <p>
            Para realizar a emissão de SnailCoin e iniciar um contrato connosco é necessário realizarmos uma breve
            proposta.
          </p>
        </div> -->

      <div class="col-lg-12">
        <div class="separator separator-dashed separator-border-4 mb-2"></div>
        <h4 class="font-size-h4 text-muted">Fatura de emissão de documentos</h4>
        <div class="separator separator-dashed separator-border-4 mb-4"></div>

        <div class="alert alert-success mb-5 p-5" role="alert">
          <h4 class="alert-heading">Olá, {{ nameLastname(currentCustomer.name) }}</h4>
          <p>
            Para validarmos seu imóvel precisamos validar a matrícula e emitir a certidão de onus, juntamente com
            cartório.
            <br />
            Pensando nisso, nós da Snailbank iremos resolver tudo para você.
          </p>
          <div class="border-bottom border-white opacity-20 mb-5"></div>
          <p class="mb-0">
            Para que possamos emitir os documentos, irá ser gerada uma fatura no valor dos serviços. Logo após o
            pagamento, iremos emitir tudo para você.
          </p>
        </div>

        <div class="table-responsive">
          <b-table
            responsive
            :fixed="true"
            class="table table-head-custom table-vertical-center"
            :items="proposal_show.asset.invoice_validation"
            :fields="fields_invoice"
            head-variant="light"
            :busy="isLoading"
            stacked="sm"
            table-variant="secondary"
            hover
            show-empty
          >
            <!-- Empty table -->
            <template #empty>
              <div class="text-center mt-5"><i class="fa fa-2x fa-times text-danger mr-2"></i></div>
              <div class="text-center">Não existem depósitos.</div>
            </template>

            <!-- Busy table -->
            <template #table-busy>
              <div class="text-center p-5"><span class="spinner spinner-primary mb-20"></span></div>
              <div class="text-center mt-5">
                <h5>Aguarde enquanto carregamos seus depósitos.</h5>
              </div>
            </template>

            <template v-slot:cell(index)="data">
              {{ data.index + 1 }}
            </template>

            <template #cell(id)="row">
              <span class="font-weight-bolder text-info mb-0 font-size-lg">{{ row.item.id }}</span>
            </template>

            <template #cell(created_at)="row">
              <span>
                <div class="font-weight-bolder text-primary mb-0 font-size-lg">
                  {{ $moment(row.item.created_at).format('DD/MM/YYYY') }}
                </div>
                <div class="text-muted">{{ $moment(row.item.created_at).format('hh:mm:ss') }}</div>
              </span>
            </template>

            <template #cell(final_amount)="row">
              <span>
                {{ row.item.final_amount | currency('R$ ') }}
              </span>
            </template>

            <template #cell(status)="row">
              <span class="badge" :class="'badge-' + row.item.status.class">{{ row.item.status.name }}</span>
            </template>

            <template #cell(actions)="row">
              <InvoiceActions :invoice="row.item" />
            </template>
          </b-table>
        </div>

        <ModalPayInvoice v-if="selectedInvoice" title="Cobrança" />
        <ModalPayMethodSelect v-if="selectedInvoice" />
        <ModalReceipt v-if="selectedInvoiceReceipt" />
      </div>

      <div v-if="pageOfItemsDocuments" class="col-lg-12">
        <div class="separator separator-dashed separator-border-4 mb-2"></div>
        <h4 class="font-size-h4 text-muted">Documentos Emitidos</h4>
        <div class="separator separator-dashed separator-border-4 mb-4"></div>
        <div class="alert alert-warning" role="alert">
          Os documentos estão sendo emitidos, isso pode levar até 72 horas.
        </div>

        <div class="table-responsive">
          <b-table
            responsive
            class="table table-head-custom table-vertical-center"
            :items="proposal_show.steps.details[step].documents"
            :fields="fields_documents"
            head-variant="light"
            :busy="isLoading"
            :fixed="true"
            stacked="sm"
            table-variant="secondary"
            hover
            show-empty
          >
            <!-- Empty table -->
            <template #empty>
              <div class="text-center mt-5"><i class="fa fa-2x fa-times text-danger mr-2"></i></div>
              <div class="text-center">Não existem depósitos.</div>
            </template>

            <!-- Busy table -->
            <template #table-busy>
              <div class="text-center p-5"><span class="spinner spinner-primary mb-20"></span></div>
              <div class="text-center mt-5">
                <h5>Aguarde enquanto carregamos os documentos do seu imóvel.</h5>
              </div>
            </template>

            <template #cell(created_at)="row">
              <span>
                <div class="font-weight-bolder text-primary mb-0 font-size-lg">
                  {{ $moment(row.item.created_at).format('DD/MM/YYYY') }}
                </div>
                <div class="text-muted">{{ $moment(row.item.created_at).format('hh:mm:ss') }}</div>
              </span>
            </template>

            <template #cell(protocol)="row">
              <span class="font-weight-bolder text-info mb-0 font-size-lg">{{ row.item.protocol }}</span>
            </template>

            <template #cell(description)="row">
              {{ row.item.description }}
            </template>

            <template #cell(status)="row">
              <span class="badge" :class="'badge-' + row.item.status.class">{{ row.item.status.name }}</span>
            </template>

            <template #cell(file)="row">
              <a
                :ref="'btn_receipt_' + row.id"
                class="btn btn-success btn-sm font-weight-bolder disabled"
                @click="receipt(invoice.id)"
              >
                <span class="svg-icon svg-icon-md svg-icon-white">
                  <inline-svg src="/media/svg/icons/Files/Cloud-download.svg" />
                </span>
                Download
              </a>
            </template>
          </b-table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import InvoiceActions from '@/shared/components/invoice/InvoiceActions';
import invoiceModalsMixin from '@/core/mixins/invoiceModalsMixin';

export default {
  name: 'ImmobileValidateCard',
  components: {
    InvoiceActions
  },
  mixins: [invoiceModalsMixin],
  props: {
    step: {
      type: Number,
      default: null
    }
  },
  data() {
    return {
      isLoading: false,
      perPage: 10,
      fields_invoice: [
        { label: 'Data', key: 'created_at' },
        { label: 'ID', key: 'id' },
        { label: 'Valor', key: 'final_amount', sortable: false },
        { label: 'Status', key: 'status' },
        { label: 'Ações', key: 'actions' }
      ],
      fields_documents: [
        { label: 'Data', key: 'created_at' },
        { label: 'Protocolo', key: 'protocol' },
        { label: 'Documento', key: 'description' },
        { label: 'Status', key: 'status' },
        { label: 'Download', key: 'file' }
      ],
      pageOfItemsDocuments: [],
      pageOfItems: []
    };
  },
  computed: {
    ...mapGetters(['currentCustomer', 'proposal_show', 'selectedInvoice', 'selectedInvoiceReceipt'])
  }
  // async mounted() {
  //   this.isLoading = true;
  //   this.pageOfItems = await this.proposal_show.asset.invoice_validation;

  //   if (this.proposal_show.steps.details[this.step].documents) {
  //     this.pageOfItemsDocuments = this.proposal_show.steps.details[this.step].documents;
  //   }

  //   this.isLoading = false;
  // }
};
</script>

<style></style>
