<template>
  <div>
    <div class="row">
      <!-- <div class="alert alert-success mb-5 p-5" role="alert">
          <h4 class="alert-heading">Informações importantes!</h4>
          <p>
            Para realizar a emissão de SnailCoin e iniciar um contrato conosco é necessario realizarmos uma breve
            proposta.
          </p>
        </div> -->

      <div class="col-lg-12">
        <div class="card card-custom mb-2 bg-diagonal bg-diagonal-light-primary">
          <div class="card-body">
            <div class="d-flex align-items-center justify-content-between p-4">
              <!--begin::Content-->
              <div class="d-flex flex-column">
                <span class="h1 text-dark display-2 mb-5">Parabéns!</span>
                <span class="h4 text-dark mb-5">Seu limite foi implantando.</span>
                <p class="text-info h4 mb-10">
                  A partir de agora o valor de
                  <b>SN$50.000,00</b>
                  se encontra disponível para utilização.
                </p>
              </div>
              <!--end::Content-->
              <!--begin::Button-->
              <div class="ml-6 flex-shrink-0" style="margin-top: -200px; margin-right: -100px; margin-bottom: -100px">
                <lottie-player
                  autoplay
                  loop
                  mode="normal"
                  style="height: 600px"
                  src="https://assets10.lottiefiles.com/private_files/lf30_9142zhsb.json"
                ></lottie-player>
              </div>
              <!--end::Button-->
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style></style>
