<template>
  <b-form class="form" @submit.stop.prevent="onSubmit">
    <h5 class="font-weight-bold mt-10 mb-6 text-info">Endereço do seu imóvel</h5>

    <b-alert :variant="gettingCep.status" :show="gettingCep.show" dismissible>{{ gettingCep.message }}</b-alert>

    <div class="row">
      <div class="col-xl-3">
        <b-form-group label="Digite o cep">
          <b-form-input
            v-model="$v.form.zip_code.$model"
            v-mask="'#####-###'"
            class="form-control form-control-solid h-auto py-5 px-6 rounded"
            :state="validateState('zip_code')"
            @keyup.native="searchCep"
          />
          <b-form-invalid-feedback>Digite seu cep!</b-form-invalid-feedback>
        </b-form-group>
      </div>
      <div class="col-xl-6">
        <b-form-group label="Endereço:" label-for="address">
          <b-form-input
            v-model="$v.form.address.$model"
            type="text"
            class="form-control form-control-solid h-auto py-5 px-6 rounded"
            :state="validateState('address')"
          />
          <b-form-invalid-feedback>O campo endereço é obrigatório.</b-form-invalid-feedback>
        </b-form-group>
      </div>
      <div class="col-xl-3">
        <b-form-group label="Numéro:" label-for="number">
          <b-form-input
            ref="number"
            v-model="$v.form.number.$model"
            type="text"
            class="form-control form-control-solid h-auto py-5 px-6 rounded"
            placeholder=""
            :state="validateState('number')"
          />
          <b-form-invalid-feedback>O campo número é obrigatório.</b-form-invalid-feedback>
        </b-form-group>
      </div>
    </div>

    <div class="row">
      <div class="col-xl-6">
        <b-form-group label="Bairro:" label-for="neighborhood">
          <b-form-input
            v-model="$v.form.neighborhood.$model"
            type="text"
            class="form-control form-control-solid h-auto py-5 px-6 rounded"
            placeholder=""
            :state="validateState('neighborhood')"
          />
          <b-form-invalid-feedback>O campo bairro é obrigatório.</b-form-invalid-feedback>
        </b-form-group>
      </div>

      <div class="col-xl-3">
        <b-form-group label="Estado:" label-for="state">
          <b-form-select
            v-model="$v.form.ecosystem_state_id.$model"
            :options="optionsState"
            class="form-control form-control-solid h-auto py-5 px-6 rounded"
            :state="validateState('ecosystem_state_id')"
            value-field="state_id"
            text-field="state_name"
          >
            <template #first>
              <b-form-select-option value="" disabled>Selecione o estado</b-form-select-option>
            </template>
          </b-form-select>
          <b-form-invalid-feedback>Selecione o estado.</b-form-invalid-feedback>
        </b-form-group>
      </div>

      <div class="col-xl-3">
        <b-form-group label="Cidade:" label-for="city">
          <b-form-select
            v-model="$v.form.ecosystem_city_id.$model"
            :options="optionsCities"
            class="form-control form-control-solid h-auto py-5 px-6 rounded"
            :state="validateState('ecosystem_city_id')"
            value-field="id"
            text-field="name"
          >
            <template #first>
              <b-form-select-option value="" disabled>Selecione a cidade</b-form-select-option>
            </template>
          </b-form-select>
          <b-form-invalid-feedback>Selecione a cidade.</b-form-invalid-feedback>
        </b-form-group>
      </div>
    </div>

    <h5 class="font-weight-bold mt-10 mb-6 text-info">Dados sobre o seu imóvel</h5>

    <div class="row">
      <div class="col-xl-7">
        <b-form-group label="Cartório que foi registrado:">
          <b-overlay :show="busyOffices" rounded="lg" opacity="0.6">
            <b-form-select
              v-model="$v.form.ecosystem_notary_office_id.$model"
              class="form-control form-control-solid h-auto py-5 px-6 rounded"
              :state="validateState('ecosystem_notary_office_id')"
            >
              <template #first>
                <b-form-select-option :value="null" disabled>Selecione o cartório</b-form-select-option>
              </template>

              <b-form-select-option-group v-for="(option, index) in optionsNotaryOffice" :key="index" :label="index">
                <b-form-select-option v-for="(notaryoffice, i) in option" :key="i" :value="notaryoffice.id">
                  {{ notaryoffice.name }}
                </b-form-select-option>
              </b-form-select-option-group>
            </b-form-select>
            <b-form-invalid-feedback>Selecione o estado.</b-form-invalid-feedback>
          </b-overlay>
        </b-form-group>
      </div>

      <div class="col-xl-2">
        <b-form-group label="Matricula:">
          <b-form-input
            v-model="$v.form.registration.$model"
            type="text"
            class="form-control form-control-solid h-auto py-5 px-6 rounded"
            placeholder=""
            :state="validateState('registration')"
          />
          <b-form-invalid-feedback>O campo obrigatório.</b-form-invalid-feedback>
        </b-form-group>
      </div>

      <div class="col-xl-2">
        <b-form-group label="Tipo:">
          <b-form-radio-group
            v-model="$v.form.type_id.$model"
            :options="optionsAssetType"
            :state="validateState('type_id')"
            buttons
            button-variant="outline-primary"
            name="radio-btn-outline"
          />
          <b-form-invalid-feedback>O campo obrigatório.</b-form-invalid-feedback>
        </b-form-group>
      </div>
    </div>

    <div class="row">
      <div class="col-lg-12">
        <!--begin::Action-->
        <div class="form-group d-flex justify-content-center">
          <button
            ref="btn-submit-asset"
            type="submit"
            class="btn btn-primary font-weight-bold px-20 py-4 font-size-3"
            :class="{ 'spinner spinner-light spinner-right': isSubmitting }"
            :disabled="isSubmitting"
          >
            Salvar dados do meu imóvel
          </button>
        </div>
        <!--end::Action-->
      </div>
    </div>
  </b-form>
</template>

<script>
import { validationMixin } from 'vuelidate';
import { required } from 'vuelidate/lib/validators';
import addressMixin from '@/core/mixins/addressMixin';
import cep from 'cep-promise';

export default {
  name: 'ImmobileCard',
  mixins: [validationMixin, addressMixin],
  props: {
    proposal: {
      type: Object,
      default: null
    }
  },
  data() {
    return {
      busyOffices: false,
      isSubmitting: false,
      gettingCep: {
        show: false,
        status: '',
        message: ''
      },
      form: {
        proposal_id: this.$route.params.id,
        zip_code: null,
        address: null,
        neighborhood: null,
        number: null,
        ecosystem_state_id: null,
        ecosystem_city_id: null,
        type_id: null,
        ecosystem_notary_office_id: null,
        registration: null
      },
      optionsNotaryOffice: null,
      optionsAssetSettled: [
        { text: 'SIM', value: '1' },
        { text: 'NÃO', value: '2' }
      ],
      optionsAssetType: [
        { text: 'Casa', value: '1' },
        { text: 'Apartamento', value: '2' }
      ],
      optionsState: [],
      optionsCities: []
    };
  },
  validations: {
    form: {
      zip_code: {
        regexZipCode(zip_code) {
          return /^[0-9]{5}-[0-9]{3}$/.test(zip_code);
        }
      },
      address: {
        required
      },
      number: {
        required
      },
      neighborhood: {
        required
      },
      ecosystem_state_id: {
        required
      },
      ecosystem_city_id: {
        required
      },
      type_id: {
        required
      },
      registration: {
        required
      },
      ecosystem_notary_office_id: {
        required
      }
    }
  },
  watch: {
    'form.ecosystem_state_id': async function (state) {
      this.form.ecosystem_city_id = '';
      this.optionsCities = await this.getCitiesByStateID(state);
    }
  },
  async mounted() {
    this.optionsState = await this.getStates();
  },
  methods: {
    async onSubmit() {
      //submitting
      this.isSubmitting = true;
      this.$v.form.$touch();
      if (this.$v.form.$anyError) {
        this.isSubmitting = false;
        return;
      }

      // send register request
      await this.$store
        .dispatch('STORE_IMMOBILE', this.form)
        .then((response) => {
          this.$swal({
            title: response.success,
            icon: 'success'
          });

          this.$store.dispatch('GET_PROPOSAL_BY_ID', this.$route.params.id);

          this.isSubmitting = false;
        })
        .catch((error) => {
          this.$swal({
            title: error,
            icon: 'error'
          });
          this.isSubmitting = false;
        });
    },
    async getNotaryOffice() {
      this.busyOffices = true;

      await this.$store
        .dispatch('GET_NOTARY_OFFICE_BY_CITY_ID', this.form.ecosystem_state_id)
        .then((response) => {
          this.optionsNotaryOffice = response;

          this.busyOffices = false;
        })
        .catch(() => {
          this.busyOffices = false;
        });
    },
    searchCep() {
      if (/^[0-9]{5}-[0-9]{3}$/.test(this.$v.form.zip_code.$model)) {
        this.gettingCep.show = true;
        this.gettingCep.message = 'Buscando...';
        this.gettingCep.status = 'warning';

        cep(this.form.zip_code, { timeout: 5000, providers: ['brasilapi'] })
          .then(async (response) => {
            const state = Object.values(this.optionsState).filter((state) => state.state_uf == response.state);
            this.$v.form.ecosystem_state_id.$model = state[0].state_id;

            this.optionsCities = await this.getCitiesByStateID(state[0].state_id);
            const city = Object.values(this.optionsCities).filter((city) => city.name == response.city);
            this.$v.form.ecosystem_city_id.$model = city[0].id;

            this.$v.form.address.$model = response.street;
            this.$v.form.neighborhood.$model = response.neighborhood;
            this.$refs['number'].focus();

            this.gettingCep.message = 'Cep encontrado com sucesso!';
            this.gettingCep.status = 'success';

            this.getNotaryOffice();
          })
          .catch(() => {
            this.gettingCep.message = 'Ops... Cep incorreto ou não encontrado!';
            this.gettingCep.status = 'danger';
          });

        // this.gettingCep.show = true;
        // this.gettingCep.message = 'Buscando...';
        // this.gettingCep.status = 'warning';
        // ApiService.get(`/profile/address/getCep/${this.$v.form.zip_code.$model}`)
        //   .then(async ({ data }) => {
        //     this.$v.form.address.$model = data.logradouro;
        //     this.$v.form.ecosystem_state_id.$model = this.optionsState[data.uf].id;
        //     this.$v.form.neighborhood.$model = data.bairro;
        //     this.optionsCities = await this.getCityByStateID(this.ecosystem_state_id);
        //     this.$refs['number'].focus();
        //     const search_id_city = this.optionsCities.filter(function(obj) {
        //       return obj.name == data.localidade;
        //     })[0];
        //     this.$v.form.ecosystem_city_id.$model = search_id_city.value;
        //     //alert cep
        //     this.gettingCep.message = 'Cep encontrado com sucesso!';
        //     this.gettingCep.status = 'success';
        //   })
        //   .catch(() => {
        //     this.gettingCep.message = 'Ops... Cep incorreto ou não encontrado!';
        //     this.gettingCep.status = 'danger';
        //   });
      }
    },
    validateState(name) {
      const { $dirty, $error } = this.$v.form[name];
      return $dirty ? !$error : null;
    }
  }
};
</script>

<style></style>
