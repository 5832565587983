<template>
  <div>
    <div class="row">
      <!-- <div class="alert alert-success mb-5 p-5" role="alert">
          <h4 class="alert-heading">Informações importantes!</h4>
          <p>
            Para realizar a emissão de SnailCoin e iniciar um contrato conosco é necessario realizarmos uma breve
            proposta.
          </p>
        </div> -->

      <div class="col-lg-12">
        <ImmobileNew v-if="proposal.asset.length === 0" />
        <ImmobileShow v-else :asset="proposal.asset" />
      </div>
    </div>
  </div>
</template>

<script>
import ImmobileNew from './ImmobileNew';
import ImmobileShow from './ImmobileShow';

export default {
  name: 'ImmobileCard',
  components: {
    ImmobileNew,
    ImmobileShow
  },
  props: {
    proposal: {
      type: Object,
      default: null
    }
  }
};
</script>

<style></style>
