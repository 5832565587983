<template>
  <div>
    <div class="row">
      <!-- <div class="alert alert-success mb-5 p-5" role="alert">
          <h4 class="alert-heading">Informações importantes!</h4>
          <p>
            Para realizar a emissão de SnailCoin e iniciar um contrato conosco é necessario realizarmos uma breve
            proposta.
          </p>
        </div> -->

      <div class="col-lg-12">
        <a class="btn btn-info" href="#" role="button">Implantar meu limite</a>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style></style>
