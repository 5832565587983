<template>
  <div class="row">
    <div class="col-12">
      <h4 class="font-weight-bolder text-info mb-7">
        Endereço do seu imóvel
        <hr />
      </h4>
    </div>

    <div class="col-6 col-md-4">
      <div class="mb-8 d-flex flex-column">
        <span class="text-dark font-weight-bold mb-4">Cep do imóvel</span>
        <span class="text-muted font-size-lg badge badge-light py-5 text-left">
          {{ asset.zip_code | VMask('#####-###') }}
        </span>
      </div>
    </div>
    <div class="col-6 col-md-4">
      <div class="mb-8 d-flex flex-column">
        <span class="text-dark font-weight-bold mb-4">Endereço</span>
        <span class="text-muted font-size-lg badge badge-light py-5 text-left">{{ asset.address }}</span>
      </div>
    </div>
    <div class="col-6 col-md-4">
      <div class="mb-8 d-flex flex-column">
        <span class="text-dark font-weight-bold mb-4">Número</span>
        <span class="text-muted font-size-lg badge badge-light py-5 text-left">{{ asset.number }}</span>
      </div>
    </div>
    <div class="col-6 col-md-4">
      <div class="mb-8 d-flex flex-column">
        <span class="text-dark font-weight-bold mb-4">Bairro</span>
        <span class="text-muted font-size-lg badge badge-light py-5 text-left">{{ asset.neighborhood }}</span>
      </div>
    </div>
    <div class="col-6 col-md-4">
      <div class="mb-8 d-flex flex-column">
        <span class="text-dark font-weight-bold mb-4">Estado</span>
        <span class="text-muted font-size-lg badge badge-light py-5 text-left">{{ asset.neighborhood }}</span>
      </div>
    </div>
    <div class="col-6 col-md-4">
      <div class="mb-8 d-flex flex-column">
        <span class="text-dark font-weight-bold mb-4">Cidade</span>
        <span class="text-muted font-size-lg badge badge-light py-5 text-left">{{ asset.neighborhood }}</span>
      </div>
    </div>
    <div class="col-6 col-md-4">
      <div class="mb-8 d-flex flex-column">
        <span class="text-dark font-weight-bold mb-4">Tipo</span>
        <span class="text-muted font-size-lg badge badge-light py-5 text-left">{{ asset.type.name }}</span>
      </div>
    </div>
    <div class="col-6 col-md-4">
      <div class="mb-8 d-flex flex-column">
        <span class="text-dark font-weight-bold mb-4">Matrícula do Imóvel</span>
        <span class="text-muted font-size-lg badge badge-light py-5 text-left">{{ asset.registration }}</span>
      </div>
    </div>
    <div class="col-6 col-md-4">
      <div class="mb-8 d-flex flex-column">
        <span class="text-dark font-weight-bold mb-4">Situação da vinculação</span>
        <span class="font-size-lg badge text-left py-5" :class="'badge-' + asset.status_validation_asset.class">
          {{ asset.status_validation_asset.name }}
        </span>
      </div>
    </div>

    <div class="col-12">
      <h4 class="font-weight-bolder text-info mb-7">
        Cartório
        <hr />
      </h4>
    </div>
    <div class="col-7">
      <div class="d-flex flex-column">
        <span class="text-dark font-weight-bold mb-4">Nome:</span>
        <span class="font-size-lg badge text-left text-dark badge badge-light py-5">
          {{ asset.notary_office.name }}
        </span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    asset: {
      type: Object,
      default: null
    }
  }
};
</script>

<style></style>
