<template>
  <div>
    <div class="row">
      <!-- <div class="alert alert-success mb-5 p-5" role="alert">
          <h4 class="alert-heading">Informações importantes!</h4>
          <p>
            Para realizar a emissão de SnailCoin e iniciar um contrato conosco é necessario realizarmos uma breve
            proposta.
          </p>
        </div> -->

      <div class="col-lg-12">
        <div class="card card-custom mb-2 bg-diagonal bg-diagonal-light-primary">
          <div class="card-body">
            <div class="d-flex align-items-center justify-content-between p-4">
              <!--begin::Content-->
              <div class="d-flex flex-column mr-5">
                <a href="#" class="h4 text-dark text-hover-primary mb-5">Or Reach Us by Live Chat</a>
                <p class="text-dark-50">
                  Windows 10 automatically installs updates
                  <br />
                  to make for sure
                </p>
              </div>
              <!--end::Content-->
              <!--begin::Button-->
              <div class="ml-6 flex-shrink-0">
                <a
                  href="#"
                  data-toggle="modal"
                  data-target="#kt_chat_modal"
                  class="btn font-weight-bolder text-uppercase font-size-lg btn-primary py-3 px-6"
                >
                  Contact Us
                </a>
              </div>
              <!--end::Button-->
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style></style>
