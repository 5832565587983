<template>
  <div>
    <div class="row">
      <div class="col-12">
        <h3 class="font-weight-bolder text-dark mb-7">
          Proposta:
          <span class="text-info">{{ proposal.id }}</span>
          <hr />
        </h3>
      </div>

      <!--begin::Info-->
      <div class="col-6 col-md-4">
        <div class="d-flex flex-column">
          <span class="text-dark font-weight-bold mb-4">Data inicio da proposta</span>
          <span class="text-muted font-size-lg badge badge-light py-5 text-left">
            {{ $moment(proposal.created_at).format('DD/MM/YYYY') }} ás
            {{ $moment(proposal.created_at).format('hh:mm:ss') }}
          </span>
        </div>
      </div>
      <div class="col-6 col-md-4">
        <div class="d-flex flex-column">
          <span class="text-dark font-weight-bold mb-4">Renda Mensal</span>
          <span class="text-muted font-size-lg badge badge-light py-5 text-left">
            {{ proposal.monthly_income | currency() }}
          </span>
        </div>
      </div>
      <div class="col-6 col-md-4">
        <div class="mb-8 d-flex flex-column">
          <span class="text-dark font-weight-bold mb-4">Atividade Profissional</span>
          <span class="text-muted font-size-lg badge badge-light py-5 text-left">
            {{ proposal.professional_activity.name }}
          </span>
        </div>
      </div>
      <div class="col-6 col-md-4">
        <div class="d-flex flex-column">
          <span class="text-dark font-weight-bold mb-4">Como irá ajudar</span>
          <span class="text-muted font-size-lg badge badge-light py-5 text-left">
            {{ proposal.purpose_resource.name }}
          </span>
        </div>
      </div>
      <div class="col-6 col-md-4">
        <div class="d-flex flex-column">
          <span class="text-dark font-weight-bold mb-4">Valor estimado do seu imóvel</span>
          <span class="text-muted font-size-lg badge badge-light py-5 text-left">
            {{ proposal.asset_estimated_value }}
          </span>
        </div>
      </div>
      <div class="col-6 col-md-4">
        <div class="d-flex flex-column">
          <span class="text-dark font-weight-bold mb-4">Situação da proposta</span>
          <span class="font-size-lg badge text-left py-5" :class="'badge-' + proposal.status.class">
            {{ proposal.status.name }}
          </span>
        </div>
      </div>

      <!--end::Info-->
    </div>
    <!--begin::Buttons-->
    <div v-if="proposal.status.id == 1" class="d-flex mt-8">
      <button
        ref="btn_cancel_proposal"
        type="button"
        class="btn btn-danger btn-lg font-weight-bolder font-size-sm"
        @click="cancelProposal"
      >
        <span class="svg-icon">
          <inline-svg src="/media/svg/icons/Code/Error-circle.svg" />
          <!--end::Svg Icon-->
        </span>
        Cancelar Proposta
      </button>
    </div>
    <!--end::Buttons-->
  </div>
</template>

<script>
// import { mapGetters } from 'vuex';
export default {
  name: 'ProposalCard',
  props: {
    proposal: {
      type: Object,
      default: null
    }
  },
  methods: {
    async cancelProposal() {
      this.$swal({
        title: 'Você tem certeza que deseja cancelar a proposta ?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#d33',
        confirmButtonText: 'Sim, cancelar!',
        cancelButtonText: 'Cancelar'
      }).then(async (result) => {
        if (result.isConfirmed) {
          const cancelButton = this.$refs['btn_cancel_proposal'];
          cancelButton.classList.add('spinner', 'spinner-light', 'spinner-right');

          // send register request
          await this.$store
            .dispatch('CANCEL_PROPOSAL', this.proposal.id)
            .then((response) => {
              this.$swal({
                title: response.message,
                icon: 'success'
              });

              cancelButton.classList.remove('spinner', 'spinner-light', 'spinner-right');
              this.$store.dispatch('GET_PROPOSAL_BY_ID', this.$route.params.id);
            })
            .catch((error) => {
              this.$swal({
                title: error.data.message,
                icon: 'error'
              });
              cancelButton.classList.remove('spinner', 'spinner-light', 'spinner-right');
            });
        }
      });
    }
  }
};
</script>

<style></style>
