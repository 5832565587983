<template>
  <div class="flex-row-fluid col-lg-9">
    <div class="card card-custom gutter-b">
      <div class="card-header py-5">
        <h3 class="card-title align-items-start flex-column">
          <span class="card-label font-weight-bolder text-dark">Nova Proposta</span>
          <span class="text-muted mt-3 font-weight-bold font-size-sm">Iniciando nova proposta</span>
        </h3>
        <div class="card-toolbar">
          <router-link v-slot="{ href, navigate }" custom to="/emission/proposals">
            <a :href="href" class="btn btn-info font-weight-bolder font-size-sm" @click="navigate">
              <span class="svg-icon svg-icon-md svg-icon-white">
                <inline-svg src="/media/svg/icons/Communication/Outgoing-box.svg" />
              </span>
              Minhas propostas
            </a>
          </router-link>
        </div>
      </div>

      <div class="card-body">
        <b-progress max="100" show-progress variant="info" height="1.5rem" class="mb-5">
          <b-progress-bar :value="Math.round((currStep * 100) / sections.length)">
            <span>
              Progresso:
              <strong>{{ Math.round((currStep * 100) / sections.length) }} %</strong>
            </span>
          </b-progress-bar>
        </b-progress>

        <template v-if="isLoading">
          <BusyTable message="Aguarde enquanto carregamos a proposta." />
        </template>

        <v-expansion-panels v-else v-model="expandSection" popout @expand="onFocus($event, n)">
          <v-expansion-panel
            v-for="section in sections"
            :key="`expansion-${section.id}`"
            :value="section.id == expandSection"
            :disabled="disabled(section.id, currStep)"
          >
            <v-expansion-panel-header disable-icon-rotate>
              <v-icon>{{ section.header.icon }}</v-icon>
              {{ section.header.title }}
              <template v-slot:actions>
                <v-icon
                  v-if="proposal_show.steps.details[section.id]"
                  :color="statusStepIcon(proposal_show.steps.details[section.id]).color"
                >
                  {{ statusStepIcon(proposal_show.steps.details[section.id]).icon }}
                </v-icon>
              </template>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <template v-if="!proposal_show">
                <div class="text-center p-10">
                  <span class="spinner spinner-primary"></span>
                  <h5 class="mt-10">Carregando proposta...</h5>
                </div>
              </template>
              <component :is="section.component" v-else :step="section.id" :proposal="proposal_show" />
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import ProposalCard from './ShowCards/Proposal/ProposalCard';
import ImmobileCard from './ShowCards/Immobile/ImmobileCard';
import ImmobileValidateCard from './ShowCards/ImmobileValidate/ImmobileValidateCard';
import EvaluationCard from './ShowCards/Evaluation/EvaluationCard';
import BusyTable from '@/shared/components/extras/BusyTable';
import EmissionCard from './ShowCards/Emission/EmissionCard';
import ContractSignCard from './ShowCards/ContractSign/ContractSignCard';

export default {
  name: 'ShowProposal',
  components: {
    BusyTable
  },
  data() {
    return {
      isLoading: false,
      expandSection: null,
      currStep: null,
      proposal_status_id: null,
      openSection: true,
      sections: [
        {
          id: 1,
          header: {
            title: 'Envio da proposta',
            icon: 'mdi-file-document-edit-outline'
          },
          component: ProposalCard
        },
        {
          id: 2,
          header: {
            title: 'Registro do imóvel',
            icon: 'mdi-home-map-marker'
          },
          component: ImmobileCard
        },
        {
          id: 3,
          header: {
            title: 'Emissão de certidão de ônus e validação de matrícula',
            icon: 'mdi-file-pdf'
          },
          component: ImmobileValidateCard
        },
        {
          id: 4,
          header: {
            title: 'Avaliação do imóvel',
            icon: 'mdi-shield-home-outline'
          },
          component: EvaluationCard
        },
        {
          id: 5,
          header: {
            title: 'Assinatura de contratos',
            icon: 'mdi-clipboard-check'
          },
          component: ContractSignCard
        },
        {
          id: 6,
          header: {
            title: 'Emissão de SnailCoin',
            icon: 'mdi-bank'
          },
          component: EmissionCard
        }
      ]
    };
  },
  computed: {
    ...mapGetters(['proposal_show'])
  },
  watch: {
    async proposal_show() {
      this.expandSection = this.proposal_show.steps.current_step.step.id - 1;
      this.currStep = this.proposal_show.steps.current_step.step.id;
      this.proposal_status_id = this.proposal_show.status_id;
    }
  },
  async mounted() {
    this.isLoading = true;
    this.$store.dispatch('SET_BREADCRUMB', [
      { title: 'Emissão de SnailCoin' },
      { title: 'Minhas Propostas' },
      { title: 'Proposta: ' + this.$route.params.id }
    ]);

    await this.$store.dispatch('GET_PROPOSAL_BY_ID', this.$route.params.id);

    this.isLoading = false;
    this.expandSection = this.proposal_show.steps.current_step.step.id - 1;
    this.currStep = this.proposal_show.steps.current_step.step.id;
    this.proposal_status_id = this.proposal_show.status_id;
  },
  methods: {
    disabled: function (n, step) {
      if (n > step) {
        return true;
      }
    },
    statusStepIcon: function (proposal_step) {
      if (!proposal_step) {
        return {
          icon: 'mdi-alert-circle',
          color: 'success'
        };
      }

      if (proposal_step.status) {
        return {
          icon: 'mdi-check-circle',
          color: 'success'
        };
      } else {
        return {
          icon: 'mdi-alarm',
          color: 'warning'
        };
      }
    }
  }
};
</script>

<style lang="scss">
.success--text {
  color: rgb(41, 202, 9) !important;
}
.warning--text {
  color: rgb(228, 213, 0) !important;
}
.danger--text {
  color: #f64e60 !important;
}
.v-expansion-panel-header__icon {
  margin-left: auto;
}
.v-expansion-panel {
  border: 1px solid #f3f6f9;
  border-bottom-left-radius: 0.42rem;
  border-bottom-right-radius: 0.42rem;
  margin-bottom: 10px;
}
.v-expansion-panel-header {
  background-color: #f3f6f9 !important;
  border-radius: 0.42rem;
  font-size: 1.1rem !important;
  font-weight: 500 !important;
}
.v-expansion-panel--disabled .v-expansion-panel-header {
  i {
    color: #bbb8b8 !important;
  }
}

.v-expansion-panel::before {
  box-shadow: none !important;
}

.v-expansion-panel-content__wrap {
  margin: 20px 0;
  background-color: #fff !important;
}

.v-expansion-panel::after {
  border-top: none !important;
}

.v-expansion-panel-header > *:not(.v-expansion-panel-header__icon) {
  flex: none !important;
  margin-right: 1rem;
  font-size: 1.4rem;
}
</style>
